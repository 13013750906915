import { handleQueryResolve } from '../utils'

export default function (rxNum) {
  const attributes = []

  const queryStr = `
  SELECT 
  RxMix.ID,
  RxNum,
  DrgID,
  DrgPackID,
  RxMix.DIN,
  PackSize,
  Qty,
  Cost,
  PerUnits,
  ActiveIngredient,
  InventoryUsed,
  LotNum,
  ExpiryDate,
  DrgPackTierId,
  AcqCost,
  IsSupply,
  drug.BrandName,
  drug.Strength,
  drug.GenericName
FROM Pharmacy.dbo.RxMix
INNER JOIN Pharmacy.dbo.Drg as drug ON Pharmacy.dbo.RxMix.DrgID = drug.ID
WHERE RxNum = ${rxNum}
      `

  // console.log(queryStr)

  return this.query(queryStr, attributes).then(handleQueryResolve)
}
